import { useState, useEffect, useContext } from 'react';
import { getUserProfile } from '../api/getUserProfile.api';
import { verifyToken } from '../contexts/JWTContext';
import AuthContext from '../contexts/JWTContext';
import { LOGIN } from 'store/actions';


const useAuth = () => {
    const context = useContext(AuthContext);
    const { user: user, dispatch } = context;


    useEffect(() => {
        const refreshToken = window.localStorage.getItem('refreshToken');
        if (!user && refreshToken && verifyToken(refreshToken)) {
            getUserProfile().then((response) => {
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response[0],
                    },
                });
            });
        }
    }, [user, getUserProfile]);

    if (!context) throw new Error("context must be use inside provider");

    return { ...context, user }; // return user from state instead of context
};

export default useAuth;