// ErrorBoundary.js
import React from "react";
import log from "loglevel";
import moment from "moment";
import PropTypes from "prop-types";
import { ErrorContext } from "./ErrorContext";
import Error from "views/pages/maintenance/Error"

// ------------Maybe Look into Loggly for analytics on errors----------------

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            message: window.location.protocol === 'https:'
                ? null
                : "Please Note: The connection to this browser may be unsecure.",
            setError: this.setError.bind(this),
            setMessage: this.setMessage.bind(this)
        };
    }

    setError(error) {
        this.setState({error});
    }

    setMessage(message) {
        this.setState({message});
    }

    static getDerivedStateFromError(error) {
        return { error };
    }

    componentDidCatch(error, info) {
        const timestamp = moment().format();
        log.error(`Uncaught error at ${timestamp}:`, error, info);
    }

    render() {
        if (this.state.error) {
            return <Error error={this.state.error} />   // render Error component if there's error
        }
        return (
            <ErrorContext.Provider value={this.state}>
                {this.props.children}
            </ErrorContext.Provider>
        );
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node,
};

export default ErrorBoundary;