import axios from 'axios';
import {getRefreshToken} from 'api/auth';
import localStorage from "redux-persist/es/storage";
import {environment} from "../config";

const axiosServices = axios.create({
    baseURL: environment.API_URL
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

axiosServices.interceptors.request.use(async config => {
    let token = await localStorage.getItem('serviceToken');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers['X-Requested-With-Protocol'] = window.location.protocol;

    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosServices.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const originalRequest = error.config;

    if (error.response.status === 404) {
        console.error(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {

        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({resolve, reject});
            }).then(token => {
                originalRequest.headers.Authorization = 'Bearer ' + token;
                return axiosServices(originalRequest);
            });
        }

        isRefreshing = true;
        const refreshToken = window.localStorage.getItem('refreshToken');

        return getRefreshToken(refreshToken).then(data => {

            localStorage.setItem('serviceToken', data.accessToken);
            axiosServices.defaults.headers.common.Authorization = `Bearer ${data.accessToken}`;
            originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;
            processQueue(null, data.accessToken);

            originalRequest._retry = true;
            isRefreshing = false;
            return axiosServices(originalRequest);
        }).catch(err => {

            processQueue(err, null);

            if (err.response && err.response.status === 401) {
                localStorage.removeItem('serviceToken');
                localStorage.removeItem('refreshToken');
            }

            isRefreshing = false;
            throw err;
        });
    }

    if (error.response.status === 502) {
        return new Promise((resolve, reject) => {
            console.error(error);
            reject(error);
        });
    }

    return Promise.reject(error);
});

export default axiosServices;