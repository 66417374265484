// import other from './other';
// import pages from './pages';
// import samplePage from './sample-page';
// import Fieldguide from 'views/browse-fieldGuides/Fieldguide';
import uploadPage from './upload-page';
import profilePage from './profile-page';
import templatePage from './template-page';
import FieldGuidePage from './fieldGuide-page';
import dashboardPage from './dashboard-page'
import ExplorePage from './explore-page';
import KanbanPage from './kanban-page';
import calendarPage from './calendar';
import aboutPage from './about-page';
import contactPage from './contact-page';
import siteDetails from './site-details'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [siteDetails, profilePage, uploadPage, templatePage, ExplorePage, ...( [dashboardPage])]
};

export default menuItems;