import { FormattedMessage } from 'react-intl';

// assets
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
// ==============================|| MENU ITEMS - CONTACT US PAGE ||============================== //

const icons = {
    ContactPageOutlinedIcon
};
const calendarPage = {
    id: 'contact-page',
    title: <FormattedMessage id="Contact Us" />,
    icon: icons.ContactPageOutlinedIcon,
    type: 'group',
    url: '/contact-us'
};

export default calendarPage;
