import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const UploadPage = Loadable(lazy(() => import('views/uploadSteps/UploadStep1')));
const UploadPage2 = Loadable(lazy(() => import('views/uploadSteps/UploadStep2')));
const UploadPage3 = Loadable(lazy(() => import('views/uploadSteps/UploadStep3')));
const Profile = Loadable(lazy(() => import('views/profile/social-profile')));
const AccountProfile = Loadable(lazy(() => import('views/profile/account-profile')));
const ReportingModulePage = Loadable(lazy(() => import('../views/contributions/contributions-page')));
const TemplatePage = Loadable(lazy(() => import('../views/contributions/Template')));
const EditLabelTemplatePage = Loadable(lazy(() => import('../views/contributions/utils/EditLabelTemplate')));
const DashboardPage = Loadable(lazy(() => import('views/dashboard/Dashboard')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/upload',
            element: <UploadPage />
        },
        {
            path: '/UploadStep2',
            element: <UploadPage2 />
        },
        {
            path: '/UploadStep3',
            element: <UploadPage3 />
        },
        {
            path: '/profile/:tab',
            element: <Profile />
        },
        {
            path: '/profile/settings',
            element: <AccountProfile />
        },
        {
            path: '/my-contributions',
            element: <ReportingModulePage />
        },
        {
            path: '/template-editor',
            element: <TemplatePage />
        },
        {
            path: '/editLabel',
            element: <EditLabelTemplatePage />
        },
        {
            path: '/dashboard',
            element: <DashboardPage />
        }

    ]
};

export default MainRoutes;
