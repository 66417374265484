import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
// import MinimalLayout from 'layout/MinimalLayout';
// import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import MainLayout from '../layout/MainLayout';
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('views/pages/authentication/ResetPassword3')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/Register')));
const AuthCodeVerification = Loadable(lazy(() => import('views/pages/authentication/CodeVerification')));
const HomePage = Loadable(lazy(() => import('views/home/Home')));
const FieldguidePage = Loadable(lazy(() => import('views/browse-fieldGuides/Fieldguide')));
const IndivFieldguidePage = Loadable(lazy(() => import('views/browse-fieldGuides/IndivFieldGuide')));
const ExplorePage = Loadable(lazy(() => import('views/explore-page')));
const VersionPage = Loadable(lazy(()=> import('views/version-page')));
const AboutPage = Loadable(lazy(()=> import('views/about-page')));
const ContactUsPage = Loadable(lazy(()=> import('views/contact-page')));
const NewsPage = Loadable(lazy(()=> import('views/news-page')));
const DonateNowPage = Loadable(lazy(()=> import('views/donate-page')));
const DonationPage = Loadable(lazy(()=> import('views/donation-stats-page')));
const CalendarPage = Loadable(lazy(()=> import('views/calendar-page')));
const BookPage = Loadable(lazy(()=> import('views/explore-books')));
const IndividualBookPage = Loadable(lazy(()=> import('views/explore-books/book-view')));
const ExploreTaxon = Loadable(lazy(()=> import('views/explore-taxon')));



// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (

            <GuestGuard>
                <MainLayout />
            </GuestGuard>

    ),
    children: [
        {
            path: '/',
            element: <HomePage />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/code-verification/:token',
            element: <AuthCodeVerification />
        },
        {
            path: '/home',
            element: <HomePage />
        },
        {
            path: 'testing/fieldGuide',
            element: <FieldguidePage />
        },
        {
            path: 'testing/fieldGuide/:speciesName',
            element: <IndivFieldguidePage />
        },
        {
            path: '/browse/observations',
            element: <ExplorePage />
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />
        },
        {
            path: '/reset-password/:token',
            element: <ResetPassword />
        },
        {
            path: '/version',
            element: <VersionPage />
        },
        {
            path: '/about',
            element: <AboutPage />
        },
        {
            path: '/contact-us',
            element: <ContactUsPage />
        },
        {
            path: '/news',
            element: <NewsPage/>
        },
        {
            path: '/componentTesting',
            element: <SamplePage/>
        },
        {
            path: '/donations/donate',
            element: <DonateNowPage/>
        },
        {
            path: '/donations',
            element: <DonationPage />
        },
        {
            path: '/calendar',
            element: <CalendarPage/>
        },
        {
            path: '/browse/books',
            element: <BookPage />
        },{
            path: '/browse/books/:id',
            element: <IndividualBookPage />
        },{
            path: '/browse/taxonomies',
            element: <ExploreTaxon/>
        }
    ]
};

export default LoginRoutes;
