import React from 'react';
import CasabioLogo from 'assets/Casabio CSI.png';
import Button from '@mui/material/Button';
import './FooterLayout.css';
import {Box, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const FooterLayout = () => {
    const [subscriber, setSubscriber] = React.useState('');
    const aboutHandler = () => window.open(`/about`, '_blank');
    const contactHandler = () => window.open(`/contact-us`, '_blank');
    const subscribeHandler = () => console.log("Subscribed with: ", subscriber);

    return (
        <footer className="footer">
            <div className="footer-content">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                        <div className="logo">
                            <img src={CasabioLogo} alt="Casabio logo"/>
                            <span className="company-name"><span className="green-text">Casa</span><span
                                className="blue-text">bio</span></span>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <div className="subscribe-input">
                            <Box component="form" sx={{ '& > :not(style)': { m: 1, width: '25ch', justifyContent: "center" }}}>
                                <Box>
                                    <Typography variant="h5" align="center" >Subscribe to our newsletter.</Typography>
                                </Box>
                                <TextField
                                    type="email"
                                    onChange={e => setSubscriber(e.target.value)}
                                    placeholder="Email"
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        backgroundColor: '#5fbd4a',
                                                        minWidth: '30px',
                                                        padding: '6px',
                                                    }}
                                                    onClick={subscribeHandler}>

                                                    <ChevronRightIcon />

                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>

                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <nav className="footer-navigation">
                            <p className="footer-nav-item" onClick={aboutHandler}>About Us</p>
                            <p className="footer-nav-item" onClick={contactHandler}>Contact</p>
                        </nav>
                    </Grid>

                </Grid>

            </div>
            <div className="footer-base">
                <p className="copyright">© 2024 Casabio - All Rights Reserved</p>
            </div>
        </footer>
    );
};

export default FooterLayout;