import { FormattedMessage } from 'react-intl';

// assets
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
// ==============================|| MENU ITEMS - ABOUT US PAGE ||============================== //

const icons = {
    GroupsOutlinedIcon
};
const calendarPage = {
    id: 'about-page',
    title: <FormattedMessage id="About Us" />,
    icon: icons.GroupsOutlinedIcon,
    type: 'group',
    url: '/about'
};

export default calendarPage;
